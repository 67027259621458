import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from '../../../components/Link';

import styles from './styles.module.scss';

function Card({ to, logo, title, description }) {
  return (
    <Link to={to} className={cx(styles.card, 'hvr-bob', 'animate')}>
      <div className={styles.logo}>
        <img src={logo} alt={title} />
      </div>
      <h3>{title}</h3>
      <p>{description}</p>
    </Link>
  );
}

Card.propTypes = {
  to: PropTypes.string.isRequired,
  logo: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Card;
