import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from '../../../components/Link';

import Menu from 'react-feather/dist/icons/menu';
import Github from 'react-feather/dist/icons/github';

import styles from './styles.module.scss';

function Header({ theme }) {
  return (
    <header className={cx(styles.header, styles[theme])}>
      <div className={styles.title}>
        <Link to="/">Invertase</Link>
      </div>
      <div className={styles.logo}>
        <Link to="/">
          <img
            className="hvr-bounce-in"
            src={
              theme === 'transparent'
                ? 'http://static.invertase.io/assets/invertase-white.png'
                : 'https://static.invertase.io/assets/invertase-logo-small.png'
            }
            alt="Invertase Logo"
          />
        </Link>
      </div>
      <div className={styles.menu}>
        <ul>
          <li>
            <Link to="/hire-us">Hire Us</Link>
          </li>
          <li>
            <Link to="/oss">Open Source</Link>
          </li>
          <li>
            <Link to="/what-we-do">What We Do</Link>
          </li>
          <li className={styles.icon}>
            <Link to="https://github.com/invertase/react-native-firebase">
              <Github />
            </Link>
          </li>
        </ul>
        <div>
          <Menu />
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light', 'transparent']),
};

Header.defaultProps = {
  theme: 'light',
};

export default Header;
