import React from 'react';
import WaveyLine from '../WaveyLine';
import Container from '../../../components/Container';

import styles from './styles.module.scss';

function Footer() {
  return (
    <div className={styles.container}>
      <WaveyLine color={'#0C565F'} />
      <footer className={styles.footer}>
        <Container>
          <div className={styles.grid}>
            <div className={styles.meta}>
              <img
                src="https://static.invertase.io/assets/invertase-white.png"
                alt="Invertase"
              />
              Invertase. Copyright &copy; 2017-2018.
            </div>
            <div className={styles.menu}>
              <ul>
                <li>
                  <a href="/">Homepage</a>
                </li>
                <li>
                  <a href="/">Hire Us</a>
                </li>
                <li>
                  <a href="/">Open Source</a>
                </li>
                <li>
                  <a href="/">What We Do</a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </footer>
    </div>
  );
}

export default Footer;
