import React from 'react';
import Heading from '../Heading';

import styles from './styles.module.scss';

function Content({ title, children }) {
  return (
    <div className={styles.grid}>
      <div>{title}</div>
      <p className={styles.content}>{children}</p>
    </div>
  );
}

export default Content;
