const projects = {
  rnfb: {
    key: 'rnfb',
    name: 'React Native Firebase',
    description:
      'A well tested feature rich modular Firebase implementation for React Native. Supports both iOS & Android platforms for over 15 Firebase services.',
    repo: 'invertase/react-native-firebase',

    // whether it has a docs repo
    docs: 'invertase-incubator/docs-test',

    // whether documentation has sub-modules - i.e. like rnfb has auth, db, etc
    modules: true,
    npm: 'react-native-firebase',
    collective: 'https://...',
    logo: '//static.invertase.io/assets/React-Native-Firebase.svg',
    favicon: '//static.invertase.io/assets/React-Native-Firebase.svg',
    discord: '',
    twitter: 'rnfirebase',
    category: ['react-native', 'firebase', 'android', 'ios'],
    featured: true,
    theme: {
      primary: '#E57A2E',
      secondary: '#F8C449',
    },
  },
  jet: {
    key: 'jet',
    name: 'Jet',
    description:
      'Accelerate your React Native module development by testing inside NodeJS; mock-free and native test code free. Perfect for module developers wanting full E2E testing & CI.',
    repo: 'invertase/jet',
    docs: false,
    npm: 'jet',
    collective: '',
    logo: '//static.invertase.io/assets/jet.png',
    favicon: '',
    discord: '',
    category: ['react-native', 'android', 'ios'],
    featured: true,
  },
  firepit: {
    key: 'firepit',
    name: 'Firepit',
    description: 'An ORM for Firebase Cloud Firestore',
    repo: 'invertase-incubator/firepit',
    docs: 'invertase-incubator/firepit-docs',
    npm: 'firepit',
    collective: '',
    logo: '//static.invertase.io/assets/jet.png',
    favicon: '',
    discord: '',
    category: ['firebase', 'node.js', 'orm'],
    featured: true,
    theme: {
      primary: '#f44336',
      secondary: '#369cf4',
    }
  },
  denque: {
    key: 'denque',
    name: 'Denque',
    description:
      'Extremely fast and lightweight double-ended queue implementation with zero dependencies.',
    repo: 'invertase/denque',
    docs: false,
    npm: 'denque',
    collective: '',
    logo:
      'https://camo.githubusercontent.com/dbea9e8e1413431453c9df6876dedf678c0f8a63/68747470733a2f2f692e696d6775722e636f6d2f65424e4a6c48642e706e67',
    favicon: '',
    discord: '',
    category: ['performance'],
    featured: true,
  },
  redisn: {
    key: 'redisn',
    name: 'Redis Next',
    description:
      'A work in progress next generation of redis client for Node.js. A modern, ultra performant and feature rich implementation.',
    repo: 'invertase/redisn',
    docs: false,
    npm: 'redisn',
    collective: '',
    logo:
      'https://camo.githubusercontent.com/dbea9e8e1413431453c9df6876dedf678c0f8a63/68747470733a2f2f692e696d6775722e636f6d2f65424e4a6c48642e706e67',
    favicon: '',
    discord: '',
    category: ['redis', 'performance'],
    featured: true,
  },
  babeln6: {
    key: 'babeln6',
    name: 'Babel preset for Node 6.x',
    description: 'Babel preset for Node 6.x (ES6 / ES2015)',
    repo: 'invertase/babel-preset-node6',
    docs: false,
    npm: 'babel-preset-node6',
    collective: '',
    logo:
      'https://camo.githubusercontent.com/dbea9e8e1413431453c9df6876dedf678c0f8a63/68747470733a2f2f692e696d6775722e636f6d2f65424e4a6c48642e706e67',
    favicon: '',
    discord: '',
    category: ['babel'],
    featured: false,
  },
};

const byArray = Object.values(projects);
const byRepoName = {};

for (let i = 0, len = byArray.length; i < len; i++) {
  const byArrayElement = byArray[i];
  byRepoName[byArrayElement.repo.replace('/', '-')] = Object.assign({}, byArrayElement);
}

module.exports.byKey = projects;
module.exports.byArray = byArray;
module.exports.byRepoName = byRepoName;
