import React from 'react';
import Helmet from 'react-helmet';

import Link from '../components/Link';
import Invertase from '../themes/invertase';
import Card from '../themes/invertase/Card';
import Content from '../themes/invertase/Content';
import Hero from '../themes/invertase/Hero';
import Container from '../components/Container';

import styles from './styles.module.scss';

import projects from '../../config/projects';

const IndexPage = () => (
  <Invertase>
    <Helmet>
      <title>Invertase</title>
    </Helmet>
    <Hero className={styles.hero} theme={'rainbow-vortex'}>
      <Container>
        <h1>Invertase</h1>
        <h2>
          JavaScript engineers providing bespoke applications for your next
          project
        </h2>
      </Container>
    </Hero>
    <div className={styles.content}>
      <div className={styles.projects}>
        {Object.values(projects)
          .filter(p => p.featured)
          .map(project => (
            <div>
              <Card
                key={project.npm}
                logo={project.logo}
                to={`/oss/${project.npm}`}
                title={project.name}
                description={project.description}
              />
            </div>
          ))}
      </div>
      <div className={styles.seeAll}>
        <Link to="/oss">Open Source Portfolio</Link>
      </div>
      <div className={styles.about}>
        <Container>
          <Content
            title={
              <div className={styles.logo}>
                <img
                  className="bob"
                  src="https://static.invertase.io/assets/invertase-logo-small.png"
                  alt="Invertase"
                />
              </div>
            }
          >
            Invertase is a UK based development team focusing on delivering high
            quality software services to clients worldwide. We have a strong
            presence in the open source community, maintaining many popular
            projects. You can find us on Github, Twitter and helping the
            community on Discord.
            <br />
            <br />
            We pride ourselves in developing with the latest technology, with
            years of experience between the team ranging from DevOps, web
            development, backend services and Android/iOS app development.
            <br />
            <br />
            Github Button
          </Content>
        </Container>
      </div>
      <div className={styles.grid}>
        <div className={styles.circle1} />
        <div className={styles.circle2} />
        <div className={styles.circle3} />
        <div className={styles.circle4} />
        <div className={styles.circle5} />
        <div className={styles.circle6} />
      </div>
    </div>
  </Invertase>
);

export default IndexPage;
