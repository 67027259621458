import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import WaveyLine from '../WaveyLine';

import styles from './styles.module.scss';

function Hero({ theme, className, children }) {
  return (
    <>
      <div className={cx(styles.hero, className, `theme-${theme}`)}>
        {children}
      </div>
      <div className={styles.wave}>
        <WaveyLine />
      </div>
    </>
  );
}

Hero.propTypes = {
  theme: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

Hero.defaultProps = {
  className: '',
  children: null,
};

export default Hero;
