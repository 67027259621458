import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function Heading({ children }) {
  return (
    <div className={styles.heading}>
      <h2>{children}</h2>
    </div>
  );
}

Heading.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Heading;
