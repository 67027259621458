import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import Page from '../../components/Page';
import Header from './Header';
import Footer from './Footer';

function Invertase({ theme, children }) {
  return (
    <Page>
      <Helmet>
        <link
          rel="shortcut icon"
          type="image/png"
          href="https://avatars1.githubusercontent.com/u/13588260?s=32&amp;v=4"
        />
      </Helmet>
      <Header theme={theme} />
      <div style={{ marginTop: theme === 'transparent' ? -54 : 0 }}>
        {children}
      </div>
      <Footer />
    </Page>
  );
}

Invertase.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Invertase;
